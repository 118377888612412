import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { useSortBy, useTable } from "react-table";
import "./index.css";
import moment from 'moment';
import { Switch, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const timeFrameDaysArray = [
  { label: "All", value: null },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
];

const ModuleReviews = ({ firebase }) => {
  const [modules, setModules] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [moduleReviewsName, setModuleReviewsName] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  // const [reviewTimeFrame, setReviewTimeFrame] = useState(null);
  const [showLegacyReviews, setShowLegacyReviews] = useState(false);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    const reviewsRequest = await firebase.moduleReviews().once("value");
    const modulesInfoRequest = await firebase
      .getModulesOverview()
      .once("value");
    const moduleData = reviewsRequest.val();
    const modulesOverview = modulesInfoRequest.val();

    if (moduleData && modulesOverview) {
      const moduleIds = Object.keys(moduleData);

      const moduleReviews = moduleIds.map((moduleId) => {
        let reviews = Object.entries(moduleData[moduleId]).map(([id, review]) => ({
          ...review,
          id
        }));

        console.log("reviews 47", reviews);

        const ratings = reviews
          .map((review) => review.rating || review.npsRating)
          .filter(rating => !isNaN(rating));

        const averageRating = ratings.length > 0
          ? ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length
          : 0;

        const name = modulesOverview[moduleId]?.name;

        return {
          moduleId,
          averageRating,
          name,
          reviews,
        };
      });

      setModules(moduleReviews);
    } else {
      console.log("No data available");
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Module Name", accessor: "name" },
      { Header: "Average Rating", accessor: "averageRating" },
      { Header: "Total Reviews", accessor: "totalReviews" },
      { Header: "Avg. Applicability", accessor: "avgApplicability" },
      { Header: "Avg. Recommend", accessor: "avgRecommend" },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      modules.map((module) => {
        const applicabilityRatings = module.reviews
          .map((review) => review.applicabilityRating)
          .filter(rating => !isNaN(rating));

        const recommendRatings = module.reviews
          .map((review) => review.npsRating)
          .filter(rating => !isNaN(rating));

        return {
          name: module.name,
          moduleId: module.moduleId,
          averageRating: module.averageRating.toFixed(1),
          totalReviews: module.reviews.length,
          avgApplicability: applicabilityRatings.length > 0
            ? (applicabilityRatings.reduce((acc, curr) => acc + curr, 0) / applicabilityRatings.length).toFixed(1)
            : "-",
          avgRecommend: recommendRatings.length > 0
            ? (recommendRatings.reduce((acc, curr) => acc + curr, 0) / recommendRatings.length).toFixed(1)
            : "-",
          reviews: module.reviews,
        };
      }),
    [modules]
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleDeleteReview = async (review) => {
    console.log("handleDeleteReview", review);
    try {
      await firebase.moduleReviews().child(review.lessonId).child(review.id).remove();
      // Refresh the reviews after deletion
      getReviews();
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  return (
    <div className="reviewspage__container">
      <h3>Module Ratings</h3>
      <div className="reviewstable__container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="reviews__row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          console.log("Reviews 101 | row", row);
                          const { name, reviews } = row.original;
                          setReviews(reviews);
                          setModuleReviewsName(name);
                          setModuleId(moduleId);
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      {moduleReviewsName && moduleReviewsName}
      <br />
      {console.log("reviews", reviews)}
      <div className="reviews__container">
        {reviews && reviews.length > 0 && (
          <>
            <div className="review-toggle">
              <Switch
                checked={showLegacyReviews}
                onChange={setShowLegacyReviews}
                checkedChildren="Legacy Reviews"
                unCheckedChildren="New Reviews"
              />
            </div>
            <table className="reviews-detail-table">
              <thead>
                <tr>
                  <th className="narrow-column">Date</th>
                  {showLegacyReviews ? (
                    <>
                      <th className="narrow-column">Legacy Rating</th>
                      <th className="wide-column">Legacy Review</th>
                    </>
                  ) : (
                    <>
                      <th className="narrow-column">Student Name</th>
                      <th className="narrow-column">Class</th>
                      <th className="narrow-column">Will Apply in Life Rating</th>
                      <th className="narrow-column">Recommend Module Rating</th>
                      <th className="wide-column">What challenges did you face while completing this module?</th>
                      <th className="wide-column">What changes or additions would make this module more effective?</th>
                      <th className="wide-column">Which part of the module was the most useful for you, and why?</th>
                    </>
                  )}
                  <th className="narrow-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reviews
                  .sort((a, b) => {
                    if (!a.date) return 1;
                    if (!b.date) return -1;
                    return new Date(b.date) - new Date(a.date);
                  })
                  .filter(review => showLegacyReviews
                    ? (review.rating || review.textFeedback)
                    : (!review.rating && !review.textFeedback)
                  )
                  .map((review, index) => (
                    console.log("review", review),
                    <tr key={index}>
                      <td
                        className="narrow-column"
                        data-review-id={review.lessonId || 'No ID'}
                      >
                        {isNaN(new Date(review.date))
                          ? "No date"
                          : moment(review.date).format('DD/MM/YY')}
                      </td>
                      {showLegacyReviews ? (
                        <>
                          <td className="narrow-column">{review.rating || "-"}</td>
                          <td className="wide-column">{review.textFeedback || "-"}</td>
                        </>
                      ) : (
                        <>
                          <td className="narrow-column">{review.displayName || "-"}</td>
                          <td className="narrow-column">{review.className || "-"}</td>
                          <td className="narrow-column">{review.applicabilityRating || "-"}</td>
                          <td className="narrow-column">{review.npsRating || "-"}</td>
                          <td className="wide-column">{review.challengesFeedback || "-"}</td>
                          <td className="wide-column">{review.improvementsFeedback || "-"}</td>
                          <td className="wide-column">{review.usefulPartsFeedback || "-"}</td>
                        </>
                      )}
                      <td className="narrow-column">
                        <Popconfirm
                          title="Delete this review?"
                          description="Are you sure you want to delete this review? This action cannot be undone."
                          onConfirm={() => handleDeleteReview(review)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined style={{ cursor: 'pointer', color: '#ff4d4f' }} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(withFirebase)(ModuleReviews);
