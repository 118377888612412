import React from "react";

const Insights = ({
  mostCommonDropoutQuestions,
  mostCommonNonDropoutQuestions,
  dropoutUsers,
  nonDropoutUsers,
}) => {
  return (
    <div className="questions__frequency">
      <div>
        <div className="dropout__category">Most Common Dropout Questions:</div>
        {mostCommonDropoutQuestions.map((item, index) => (
          <div key={index} className="dropout__question">
            <div className="dropout__text">{item.question}:</div>
            <div className="data__number">
              {Number((item.count / dropoutUsers.length) * 100).toFixed(2)}%
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="dropout__category">
          Most Common Non-Dropout Risk Questions:
        </div>
        {mostCommonNonDropoutQuestions.map((item, index) => (
          <div key={index} className="dropout__question">
            <div className="dropout__text">
              {item.question}:{item.count}
            </div>
            <div className="data__number">
              {Number((item.count / nonDropoutUsers.length) * 100).toFixed(2)}%
            </div>
          </div>
        ))}
      </div>
      <div className="questions__frequency">
        <table>
          <thead>
            <tr>
              <th>Dropout Questions</th>
              <th style={{ width: 130 }}>Dropout %</th>
              <th style={{ width: 130 }}>Non Dropout %</th>
              <th>Non-Dropout Questions</th>
            </tr>
          </thead>
          <tbody>
            {mostCommonDropoutQuestions.map((dropoutItem, index) => (
              <tr key={index}>
                <td>{dropoutItem.question}</td>
                <td>
                  {Number(
                    (dropoutItem.count / dropoutUsers.length) * 100
                  ).toFixed(2)}
                  %
                </td>
                <td>
                  {mostCommonNonDropoutQuestions[index]
                    ? Number(
                        (mostCommonNonDropoutQuestions[index].count /
                          nonDropoutUsers.length) *
                          100
                      ).toFixed(2)
                    : "N/A"}
                  %
                </td>
                <td>
                  {mostCommonNonDropoutQuestions[index]
                    ? mostCommonNonDropoutQuestions[index].question
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Insights;
