import React from "react";
import "./index.css";
import Icon from "../../../../../shared/Icon";

const UserNav = ({ user, history }) => {
  return (
    <div className="usernav__container">
      <div className="usernav__profile">
        {user.photoURL && (
          <div className="usernav__image-icon">
            <img
              src={user.photoURL}
              width={50}
              height={50}
              style={{ borderRadius: 25 }}
            />
          </div>
        )}
        <div className="userinfo__container">
          <div className="usernav__name">
            {user.displayName || user.username}
          </div>
          <div className="usernav__role">
            {user.isAdmin && "Admin"} {user.isMentor && "Mentor"}{" "}
            {!user.isAdmin && !user.isMentor ? "Student" : ""}
          </div>
          <div className="usernav__class-id">
            {user.classId ||
              (!user.isAdmin && user.isMentor
                ? "Please click on the classroom tab to join a classroom"
                : "Please join a classroom")}
          </div>
        </div>
      </div>
      <div className="usernav__notifications-icon">
        <div className="usernav__settings-icon">
          <div
            onClick={() => {
              // open app store link
              // https://apps.apple.com/pe/app/red-ed/id6448871174
              window.open("https://apps.apple.com/pe/app/red-ed/id6448871174");
            }}
          >
            <img
              className=""
              alt=""
              width={100}
              height={"auto"}
              src="/assets/images/app-store-download.svg"
            />
          </div>
          <Icon
            name="settings-gray"
            style={{ color: "black" }}
            onClick={() => history.push("/settings")}
          />
        </div>
      </div>
    </div>
  );
};

export default UserNav;
