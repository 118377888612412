import React, { useState } from "react";
import Button from "../shared/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FeedbackModal = ({
  finishLoading = false,
  formVisible = false,
  lessonName,
  handleFinishModule,
  lessonId,
  onClose,
}) => {
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [npsRating, setNpsRating] = useState(null);
  const [applicabilityRating, setApplicabilityRating] = useState(null);
  const [challengesFeedback, setChallengeFeedback] = useState("");
  const [usefulPartsFeedback, setUsefulPartsFeedback] = useState("");
  const [improvementsFeedback, setImprovementsFeedback] = useState("");

  const checkFormValidity = () => {
    return (
      npsRating !== null &&
      applicabilityRating !== null &&
      challengesFeedback.trim().length > 0 &&
      usefulPartsFeedback.trim().length > 0 &&
      improvementsFeedback.trim().length > 0
    );
  };

  const handleNpsRatingClick = (number) => {
    setNpsRating(number);
    setSaveEnabled(checkFormValidity());
  };

  const handleApplicabilityRatingClick = (number) => {
    setApplicabilityRating(number);
    setSaveEnabled(checkFormValidity());
  };

  const handleChallengesChange = (e) => {
    setChallengeFeedback(e.target.value);
    setSaveEnabled(checkFormValidity());
  };

  const handleUsefulPartsChange = (e) => {
    setUsefulPartsFeedback(e.target.value);
    setSaveEnabled(checkFormValidity());
  };

  const handleImprovementsChange = (e) => {
    setImprovementsFeedback(e.target.value);
    setSaveEnabled(checkFormValidity());
  };

  const handleSaveClick = async () => {
    if (saveEnabled) {
      await handleFinishModule(
        npsRating,
        challengesFeedback,
        applicabilityRating,
        usefulPartsFeedback,
        improvementsFeedback
      );
    } else {
      toast.error("Please enter some feedback before saving.");
    }
  };

  if (!formVisible) return null;

  return (
    <div className="feedbackmodal__container">
      <div className="feedbackmodal__content">
        <div className="feedbackmodal__top">
          Rate the Module: {lessonName}
          <div className="feedbackmodalheader" onClick={onClose} style={{ cursor: 'pointer' }}>X</div>
        </div>
        <div className="feedbackmodal__info">
          How likely are you to recommend this course to your friends and colleagues?
          <span style={{ fontSize: '14px', fontStyle: 'italic', color: 'grey' }}> (1 don't recommend, 10 recommend)</span>
        </div>
        <div className="feedbackmodal__scalecontainer">
          {range &&
            range.map((number, index) => {
              console.log("FeedbackModal | 16", number);
              return (
                <div
                  onClick={() => {
                    handleNpsRatingClick(number);
                  }}
                  style={{
                    backgroundColor: `${number === npsRating
                      ? "rgba(187, 65, 65, 1)"
                      : "white"
                      }`,
                    color: `${number === npsRating
                      ? "white"
                      : "rgba(187, 65, 65, 1)"
                      }`,
                  }}
                  className="feedbackmodalscale__number"
                  key={index}
                >
                  {number}
                </div>
              );
            })}
        </div>
        <div className="feedbackmodal__info">
          How likely are you to apply what you learned in real life? (Rate 1-10)
          <span style={{ fontSize: '14px', fontStyle: 'italic', color: 'grey' }}> (1 won't apply, 10 will apply)</span>
        </div>
        <div className="feedbackmodal__scalecontainer">
          {range.map((number, index) => (
            <div
              onClick={() => handleApplicabilityRatingClick(number)}
              style={{
                backgroundColor: `${number === applicabilityRating ? "rgba(187, 65, 65, 1)" : "white"}`,
                color: `${number === applicabilityRating ? "white" : "rgba(187, 65, 65, 1)"}`,
              }}
              className="feedbackmodalscale__number"
              key={`difficulty-${index}`}
            >
              {number}
            </div>
          ))}
        </div>
        <div className="feedbackinput__container">
          <div className="feedbackinput__text">
            What challenges did you face while completing this module?
          </div>
          <textarea
            value={challengesFeedback}
            onChange={handleChallengesChange}
            className="feedbackinput__input"
            placeholder="Write here"
          />
        </div>
        <div className="feedbackinput__container">
          <div className="feedbackinput__text">
            Which part of the module was the most useful for you, and why?
          </div>
          <textarea
            value={usefulPartsFeedback}
            onChange={handleUsefulPartsChange}
            className="feedbackinput__input"
            placeholder="Write here"
          />
        </div>
        <div className="feedbackinput__container">
          <div className="feedbackinput__text">
            What changes or additions would make this module more effective?
          </div>
          <textarea
            value={improvementsFeedback}
            onChange={handleImprovementsChange}
            className="feedbackinput__input"
            placeholder="Write here"
          />
        </div>
        <div className="feedbackmodal__save">
          <Button
            title={finishLoading ? "Loading..." : "Save"}
            onClick={handleSaveClick}
            type={saveEnabled ? "primary" : "disabled"}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
