import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { ToggleContentBox } from "./ToggleContentBox";

const MultiClassroomsTable = ({
  selectedQuestions,
  sortedUsers,
  setToastContent,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Classroom",
        accessor: "classId",
      },
      {
        Header: "Pre-LSI",
        accessor: "preLsi",
        Cell: ({ value }) => (value ? "✅" : "N/A"),
      },
      {
        Header: "Mid-LSI",
        accessor: "midLsi",
        Cell: ({ value }) => (value ? "✅" : "N/A"),
      },
      {
        Header: "Post-LSI",
        accessor: "postLsi",
        Cell: ({ value }) => (value ? "✅" : "N/A"),
      },
      {
        Header: "Inactive",
        accessor: "inactive",
        Cell: ({ value }) => (
          <div style={{ backgroundColor: value ? "red" : "" }}>
            {value ? "Yes" : "No"}
          </div>
        ),
        sortType: (rowA, rowB) => {
          const a = rowA.original.inactive ? 1 : 0;
          const b = rowB.original.inactive ? 1 : 0;
          return a - b;
        },
      },
      {
        Header: "LSI Risk Level",
        accessor: "riskLevel",
        Cell: ({ row }) => (
          <div
            style={{ position: "relative" }}
            className="toast__trigger"
            onClick={() => setToastContent(row.original.toastQuestions)}
          >
            <ToggleContentBox
              riskLevel={row.original.riskLevel}
              content={row.original.toastQuestions}
              name={row.original.displayName}
              selectedQuestionsNum={selectedQuestions?.length}
            />
          </div>
        ),
      },
      ...selectedQuestions.map((question) => ({
        Header: question.name,
        accessor: (row) => {
          const preAnswer = row.preLsi?.lsiAnswers
            ? Array.isArray(row.preLsi.lsiAnswers)
              ? row.preLsi.lsiAnswers
              : Object.entries(row.preLsi.lsiAnswers).map(([id, ans]) => ({
                ...ans,
                id,
              }))
            : [];
          const postAnswer = row.postLsi?.lsiAnswers
            ? Array.isArray(row.postLsi.lsiAnswers)
              ? row.postLsi.lsiAnswers
              : Object.entries(row.postLsi.lsiAnswers).map(([id, ans]) => ({
                ...ans,
                id,
              }))
            : [];

          const userAnswer = question.preLSI ? preAnswer.find(
            (answer) => answer.id === question.id
          ) : postAnswer.find(
            (answer) => answer.id === question.id
          );

          const isRisky = userAnswer
            ? question.options?.some(
              (option) => option.isRisky && option.value === userAnswer.value
            )
            : false;
          return {
            value: userAnswer
              ? question.type === "checkbox" && userAnswer.checked
                ? Object.values(userAnswer.checked)
                  .filter((item) => item.checked)
                  .map((item) => item.name)
                  .join(", ") || "N/A"
                : userAnswer.value
              : "N/A",
            isRisky,
          };
        },
        Cell: ({ value }) => (
          <div
            style={{
              backgroundColor: value.isRisky ? "#FFEBEB" : "transparent",
              width: "100%",
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: value.isRisky ? "bold" : "normal",
            }}
          >
            {value.value}
          </div>
        ),
      })),
    ],
    [selectedQuestions, setToastContent]
  );

  const data = useMemo(
    () =>
      sortedUsers.map((user) => {
        let userRiskLevel = 0;
        let toastQuestions = [];

        selectedQuestions.forEach((selectedQuestion) => {
          const preAnswer = user.preLsi?.lsiAnswers
            ? Array.isArray(user.preLsi.lsiAnswers)
              ? user.preLsi.lsiAnswers
              : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
                ...answer,
                id,
              }))
            : [];
          const postAnswer = user.postLsi?.lsiAnswers
            ? Array.isArray(user.postLsi.lsiAnswers)
              ? user.postLsi.lsiAnswers
              : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
                ...answer,
                id,
              }))
            : [];

          const userQuestion = selectedQuestion.preLSI ? preAnswer.find(
            (answer) => answer.id === selectedQuestion.id
          ) : postAnswer.find(
            (answer) => answer.id === selectedQuestion.id
          );

          if (userQuestion) {
            const questionOptions = selectedQuestion.options;

            questionOptions?.forEach((option) => {
              if (option.isRisky && option.value === userQuestion.value) {
                userRiskLevel += 1;
                toastQuestions.push({
                  ...option,
                  question: selectedQuestion.name,
                  isRisky: true,
                });
              } else if (option.value === userQuestion.value) {
                toastQuestions.push({
                  ...option,
                  question: selectedQuestion.name,
                  isRisky: false,
                });
              }
            });
          }
        });

        return {
          ...user,
          riskLevel: userRiskLevel,
          toastQuestions,
        };
      }),
    [sortedUsers, selectedQuestions]
  );

  console.log("data2", data);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="multiclassroom__table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="multiclassroom__headerrow"
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="multiclassroom__header"
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="multiclassroom__row">
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MultiClassroomsTable;
