// Function to get a specific sub-key's data from the "RED" key
export function getLocalStorageData(subKey) {
  const data = localStorage.getItem("RED");
  const parsedData = data ? JSON.parse(data) : {};
  return parsedData[subKey] || null;
}

// Function to set a specific sub-key's data within the "RED" key
export function setLocalStorageData(subKey, value) {
  const data = localStorage.getItem("RED");
  const parsedData = data ? JSON.parse(data) : {};
  parsedData[subKey] = value;
  localStorage.setItem("RED", JSON.stringify(parsedData));
}

export function removeLocalStorageData(subKey) {
  const data = localStorage.getItem("RED");
  const parsedData = data ? JSON.parse(data) : {};
  delete parsedData[subKey];
  localStorage.setItem("RED", JSON.stringify(parsedData));
}
