import React, { useState, useEffect } from "react";
import "./index.css";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "src/utils/localStorageUtils";

const MultiClassroomSelect = ({ classrooms = [], handleSubmit }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const savedClassrooms = getLocalStorageData("selectedClassrooms");
    if (savedClassrooms) {
      console.log("savedClassrooms", savedClassrooms);
      setSelectedClassrooms(savedClassrooms);
      handleSubmit(savedClassrooms);
    }
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCheckboxChange = (classroom) => {
    setSelectedClassrooms((prevSelected) => {
      const newSelected = prevSelected.includes(classroom)
        ? prevSelected.filter((item) => item !== classroom)
        : [...prevSelected, classroom];
      setLocalStorageData("selectedClassrooms", newSelected);
      return newSelected;
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApply = () => {
    console.log("Selected Classrooms:", selectedClassrooms);
    handleSubmit(selectedClassrooms);
    setLocalStorageData("selectedClassrooms", selectedClassrooms);
    setDropdownOpen(false);
  };

  const filteredClassrooms = classrooms.filter((classroom) =>
    classroom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="multi-classroom-select">
      <label>Classrooms ID:</label>
      <div className="dropdown">
        <div className="dropdown-toggle" onClick={toggleDropdown}>
          {selectedClassrooms.length > 0 ? (
            selectedClassrooms.map((classroom) => (
              <span className="dropdown__tag" key={classroom}>
                {classroom}
              </span>
            ))
          ) : (
            <span>All</span>
          )}
          <span className={`arrow ${dropdownOpen ? "open" : ""}`}>&#9660;</span>
        </div>
        {dropdownOpen && (
          <div className="dropdown-menu">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <ul>
              {filteredClassrooms.map((classroom) => (
                <li key={classroom}>
                  <label className="classroom__label">
                    <input
                      type="checkbox"
                      checked={selectedClassrooms.includes(classroom)}
                      onChange={() => handleCheckboxChange(classroom)}
                    />
                    {classroom}
                  </label>
                </li>
              ))}
            </ul>
            <button className="apply-button" onClick={handleApply}>
              Apply
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiClassroomSelect;
