import React, { useState, useEffect } from "react";
import "./index.css";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "src/utils/localStorageUtils";

export const AssessmentQuestionsMultiSelect = ({
  questions = [],
  handleSubmit,
  selectedQuestions = [],
  setSelectedQuestions,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const savedQuestions = getLocalStorageData("selectedQuestions");
    if (savedQuestions) {
      setSelectedQuestions(savedQuestions);
    }
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCheckboxChange = (question) => {
    setSelectedQuestions((prevSelected) => {
      const isSelected = prevSelected.some((q) => q.id === question.id);
      const newSelected = isSelected
        ? prevSelected.filter((item) => item.id !== question.id)
        : [...prevSelected, question];
      setLocalStorageData("selectedQuestions", newSelected);
      return newSelected;
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApply = () => {
    handleSubmit(selectedQuestions);
    setLocalStorageData("selectedQuestions", selectedQuestions);
    setDropdownOpen(false);
  };

  const filteredQuestions = questions?.filter((question) =>
    question?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="multi-classroom-select">
      <label>Risk Questions {selectedQuestions.length}</label>
      <div className="dropdown">
        <div className="dropdown-toggle" onClick={toggleDropdown}>
          {selectedQuestions.length > 0 ? (
            selectedQuestions.map((question) => (
              <span className="tag" key={question.id}>
                {question.name}
              </span>
            ))
          ) : (
            <span>All</span>
          )}
          <span className={`arrow ${dropdownOpen ? "open" : ""}`}>&#9660;</span>
        </div>
        {dropdownOpen && (
          <div className="dropdown-menu">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <ul>
              {filteredQuestions.map((question, index) => (
                <li key={index}>
                  <label className="classroom__label">
                    <input
                      type="checkbox"
                      checked={selectedQuestions.some(
                        (q) => q.id === question.id
                      )}
                      onChange={() => handleCheckboxChange(question)}
                    />
                    {question.name} -{" "}
                    {question.postLSI ? "Final LSI" : "Initial LSI"}
                  </label>
                </li>
              ))}
            </ul>
            <button className="apply-button" onClick={handleApply}>
              Apply
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentQuestionsMultiSelect;
