import React, { useState } from "react";

export const ToggleContentBox = ({
  content,
  name,
  riskLevel = 0,
  selectedQuestionsNum,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const riskyItems = content.filter((item) => item.isRisky);
  const nonRiskyItems = content.filter((item) => !item.isRisky);

  return (
    <div>
      <div
        className="toggle__box"
        onClick={(e) => {
          e.stopPropagation();
          setIsVisible(!isVisible);
        }}
        style={{
          backgroundColor: isVisible ? "green" : "gray",
          cursor: "pointer",
        }}
      >
        <div className="risk__level">{riskLevel}</div>
      </div>
      {isVisible && (
        <div className="toast__container">
          <div className="risk__container">
            <div className="risk__col">
              <div className="toast__name">
                {name} Risk Questions {riskyItems.length + nonRiskyItems.length}{" "}
                /{selectedQuestionsNum}
              </div>
              <div className="toast__columns">
                <div className="toast__column">
                  <div className="toast__column__header">Risky</div>
                  {riskyItems.map((item, index) => (
                    <div key={index} className="toast__question">
                      {item.question} - {item.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="risk__col">
                <div className="toast__column">
                  <div className="toast__column__header">Non-Risky</div>
                  {nonRiskyItems.map((item, index) => (
                    <div key={index} className="toast__question__safe">
                      {item.question} - {item.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
