/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { sampleLikertData } from "./sampleLikertData";
import { dataLength } from "src/utils/dataLength";


function LikertReport({ data, className, documentTitle, type = 'personal', users, modules }) {
  console.log("LikertReport.js 16 | modules", modules);
  const reportData = type === 'group'
    ? generateReportData(data)
    : generatePersonalReportData(data, users, modules);

  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text style={styles.pdf_page_title}>
            {className} Likert {type === 'group' ? 'Class Report' : 'Individual Report'}
          </Text>
          {type === 'group' ? (
            <>
              <View style={{ marginTop: 20, marginBottom: 20 }}>
                <Text>
                  This report provides an analysis of the class average results for various educational modules, comparing pre- and post-assessment scores, highlighting areas of success and improvement.
                </Text>
              </View>
              <AverageView reportData={reportData} />
              <View style={{
                marginTop: 20, marginBottom: 10, whiteSpace: "nowrap"
              }}>
                <Text style={styles.pdf_page_subtitle}>
                  Results Description
                </Text>
                <Text style={{ fontSize: 10, fontFamily: 'Helvetica' }}>
                  The Gwinnett 2024 Likert Report outlines the class average results for various educational modules, comparing pre- and post-assessment scores. Civic Education achieved the highest score with an average of 80%, showing no change in performance. Emotional Intelligence Part 1 exhibited the largest growth, increasing by 43% from its initial score of 20%. Other modules, such as Managing Your “E” Factor Part 1, showed moderate improvements, with a 13% increase, while Bridge The Gap saw a 16% rise. Conversely, Health & Fitness experienced a slight decrease of 1%, with no progress noted in modules like Finance Fundamentals, Career Readiness, and Entrepreneurship. Most other modules maintained their scores with no significant change.
                </Text>
              </View>
              <View style={{
                marginTop: 20, marginBottom: 20, whiteSpace: "nowrap"
              }}>
                <Text style={styles.pdf_page_subtitle}>
                  Results Analysis
                </Text>
                <Text>
                  The results indicate a mixed level of impact across the modules. Emotional Intelligence Part 1’s significant improvement suggests a strong engagement or effectiveness of the teaching strategies in that area. The growth in Managing Your “E” Factor Part 1 and Bridge The Gap further highlights areas where measurable progress has been achieved. However, the lack of improvement in several modules, such as Finance Fundamentals and Career Readiness, raises questions about either the curriculum’s effectiveness or the students' engagement in these topics. The slight decline in Health & Fitness suggests a need to revisit instructional methods or student interest levels in this module. Overall, the findings reflect areas of success as well as opportunities for improvement in curriculum delivery and student engagement strategies.
                </Text>
              </View>
            </>
          ) : (
            <PersonalView reportData={reportData} />
          )}
        </View>
      </Page>


    </Document>
  );
}

const AverageView = ({ reportData }) => (
  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    {Object.entries(reportData).map(([name, scores]) => {
      if (!scores.averagePreTestScorePercent || !scores.averagePostTestScorePercent) {
        return null;
      }
      return (
        <View style={[styles.lessonContainer, { width: '50%' }]}>
          <Text style={styles.lessonTitle}>
            {name}{" "}
            {/* {(scores.averagePostTestScorePercent - scores.averagePreTestScorePercent).toFixed(0)}% */}
          </Text>
          <DeltaBars
            preTest={scores.averagePreTestScorePercent}
            postTest={scores.averagePostTestScorePercent}
          />
        </View>
      );
    })}
  </View>
);

const getInitials = (moduleName) => {
  // Special cases for specific modules
  const specialCases = {
    'Substance Use Disorder and Addiction': 'SU',
    // Add more special cases as needed
  };

  if (specialCases[moduleName]) {
    return specialCases[moduleName];
  }

  // Default case: take first two letters of first two words
  return moduleName
    .split(' ')
    .slice(0, 2) // Only take first two words
    .map(word => word[0])
    .join('')
    .toUpperCase();
};

const PersonalView = ({ reportData }) => (
  <View>
    <View style={styles.legendContainer}>
      <Text style={styles.legendTitle}>Module Legend:</Text>
      {reportData.modules.map((module, index) => (
        <Text key={index} style={styles.legendText}>
          {getInitials(module)} - {module}
        </Text>
      ))}
    </View>

    <View style={styles.tableHeader}>
      <Text style={[styles.tableCell, styles.headerCell, { width: 120 }]}>Student Name</Text>
      {reportData.modules.map((module, index) => (
        <Text key={index} style={[styles.tableCell, styles.headerCell, { width: 40 }]}>
          {getInitials(module)}
        </Text>
      ))}
    </View>

    {reportData.students.map((student, index) => (
      <View wrap={false} key={index} style={styles.tableRow}>
        <Text
          style={[styles.tableCell, { width: 120 }]}
        >
          {student.name.length > 15
            ? `${student.name.substring(0, 15)}...`
            : student.name}
        </Text>
        {reportData.modules.map((module, moduleIndex) => {
          const change = student.modules[module]?.change;
          return (
            <Text
              key={moduleIndex}
              style={[
                styles.tableCell,
                {
                  width: 40,
                  color: change > 0 ? '#008000' : change < 0 ? '#FF0000' : '#000000'
                }
              ]}
            >
              {change ? `${change}%` : 'N/A'}
            </Text>
          );
        })}
      </View>
    ))}
  </View>
);

const generatePersonalReportData = (likertData, users, modules) => {
  console.log("LikertReport.js 140 | generating personal report with data", likertData);
  const students = users
    .filter(user => !user.isAdmin && !user.isMentor)
    .map(user => ({
      name: user.displayName || user.username,
      modules: modules.reduce((acc, module) => {
        // Find the module data for this user
        const moduleData = likertData?.find(data =>
          data?.uid === user.uid && data?.name === module.name
        );

        if (moduleData) {
          // Calculate average scores for pre and post tests
          const preScore = calculateAverageScore(moduleData.preTest);
          const postScore = calculateAverageScore(moduleData.postTest);
          const change = postScore - preScore;

          acc[module.name] = {
            change: parseFloat(change.toFixed(2)),
            preScore: parseFloat(preScore.toFixed(2)),
            postScore: parseFloat(postScore.toFixed(2))
          };
        } else {
          acc[module.name] = null;
        }

        return acc;
      }, {})
    }));

  return {
    students,
    modules: modules.map(m => m.name)
  };
};

// Helper function to calculate average score
const calculateAverageScore = (testData) => {
  if (!testData || testData.length === 0) return 0;

  const validResponses = testData.filter(item => item && item.value);
  if (validResponses.length === 0) return 0;

  const sum = validResponses.reduce((acc, item) => acc + item.value, 0);
  return (sum / (validResponses.length * 5)) * 100;
};

const DeltaBars = ({ preTest, postTest }) => {
  // Convert to numbers to ensure proper comparison
  const preTestNum = Number(preTest);
  const postTestNum = Number(postTest);

  const postTestWidth = (postTestNum / 100) * 100;
  const preTestWidth = (preTestNum / 100) * 100;

  return (
    <View style={{ width: '90%' }}>
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
      }}>
        <View style={{ width: 50, justifyContent: 'center', height: 24 }}>
          <Text style={[styles.pdf_bold, { color: 'gray', fontWeight: 'bold', fontSize: 10 }]}>Pre</Text>
        </View>
        <View style={styles.deltaBarContainer}>
          <View style={[styles.preTestBar, { width: `${preTestWidth}%` }]} />
        </View>
        <View style={{ width: 30, justifyContent: 'center', height: 24 }}>
          <Text style={[styles.deltaText, {
            color: 'rgba(0, 186, 136, 1)',
            textAlign: 'center'
          }]}>
            {`${Math.round(preTest)}`}
          </Text>
        </View>
      </View>
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
      }}>
        <View style={{ width: 50, justifyContent: 'center', height: 24 }}>
          <Text style={[styles.pdf_bold, { color: 'gray', fontWeight: 'bold', fontSize: 10 }]}>Post</Text>
        </View>
        <View style={[styles.deltaBarContainer, {
          backgroundColor: preTestNum > postTestNum ? 'rgba(217, 70, 69, 0.2)' : 'rgba(0, 186, 136, 0.2)'
        }]}>
          <View style={[styles.preTestBar, {
            width: `${postTestWidth}%`,
            backgroundColor: preTestNum > postTestNum ? 'rgba(217, 70, 69, 0.8)' : 'rgba(0, 186, 136, 1)'
          }]} />
        </View>
        <View style={{ width: 30, justifyContent: 'center', height: 24 }}>
          <Text style={[styles.deltaText, {
            color: preTestNum > postTestNum ? 'rgba(217, 70, 69, 0.8)' : 'rgba(0, 186, 136, 1)',
            textAlign: 'center',
          }]}>
            {`${Math.round(postTest)}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

const generateReportData = (data) => {
  console.log("LikertReport.js 78 | generating report with data", data);
  const result = {};
  data
    .filter((lesson) => lesson && lesson.preTest && lesson.postTest) // filter out null values and lessons without tests
    .forEach((lesson) => {

      const preTestScores = lesson.preTest
        .filter((item) => item)
        .map((item) => item.value);

      const postTestScores = lesson.postTest
        .filter((item) => item)
        .map((item) => item.value);

      const totalPreScore = preTestScores.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalPostScore = postTestScores.reduce(
        (sum, value) => sum + value,
        0
      );
      const averagePreScorePercent =
        (totalPreScore / (preTestScores.length * 5)) * 100;
      const averagePostScorePercent =
        (totalPostScore / (postTestScores.length * 5)) * 100;

      result[lesson.name] = {
        averagePreTestScorePercent: averagePreScorePercent.toFixed(2),
        averagePostTestScorePercent: averagePostScorePercent.toFixed(2),
      };
    });


  return result;
};

const styles = StyleSheet.create({
  lessonContainer: {
    marginBottom: 10,
  },
  deltaBarContainer: {
    flexDirection: "row",
    height: 16,
    backgroundColor: "#00BA8820", //green
    width: '100%',
  },
  deltaBarContainerPositive: {
    backgroundColor: "rgba(0, 186, 136, 0.2)", //green
  },
  deltaBarContainerNegative: {
    backgroundColor: "rgba(217, 70, 69, 0.2)", //red
  },
  deltaBarPositive: {
    backgroundColor: "rgba(0, 186, 136, 0.2)", //green
  },
  deltaBarNegative: {
    backgroundColor: "rgba(217, 70, 69, 0.2)", //red
  },
  preTestBar: {
    backgroundColor: "#00BA88",
    height: "100%",
  },
  deltaBar: {
    backgroundColor: "#00BA8820",
    height: "100%",
    zIndex: 10,
  },
  preTestText: {
    position: "absolute",
    left: 20,
    top: 5,
    color: "white",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  deltaText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginLeft: 5,
  },
  documentStyle: {
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },

  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
  },

  pdf_page_subtitle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 14,
    paddingBottom: 5,
  },

  pdf_normal_space: {
    marginTop: 10,
  },

  pdf_big_space: {
    marginTop: 20,
  },

  pdf_bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },


  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  greenTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  redContainer: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Helvetica-Bold",
  },

  greenContainer: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: "#4CAF50",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Helvetica-Bold",
  },

  entryContainer: {
    paddingBottom: 10,
  },

  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    backgroundColor: '#f5f5f5',
    height: 30,
  },

  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#ccc',
    height: 30,
  },

  tableCell: {
    margin: 2,
    padding: 4,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 0.5,
    borderRightColor: '#ccc',
    height: '100%',
  },

  headerCell: {
    fontFamily: 'Helvetica-Bold',
    backgroundColor: '#f5f5f5',
  },

  lessonTitle: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    paddingRight: 20,
  },

  legendContainer: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
  },

  legendTitle: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },

  legendText: {
    fontSize: 8,
    marginBottom: 2,
  },
});

export default LikertReport;
