import React, { useContext, useEffect, useState } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import {
  getLocalStorageData,
  removeLocalStorageData,
  setLocalStorageData,
} from "src/utils/localStorageUtils";
import MultiClassroomSelect from "../shared/ClassroomsMultiSelect";

import LSIAssessmentQuestionsMultiSelect from "../shared/LSIAssessmentQuestionsMultiSelect";

import "./index.css";
import { Spin, Button, Input, Modal } from "antd"; // added imports
import { toast } from "react-toastify";
import { dataLength } from "src/utils/dataLength";
import Papa from "papaparse";
import Insights from "./Insights";
import MultiClassroomsTable from "./MultiClassroomsTable";

const MultiClassroomView = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [allClassrooms, setAllClassrooms] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [lsiData, setLsiData] = useState(null);
  const [assessmentQuestions, setAssessmentQuestions] = useState(null);
  const [toastContent, setToastContent] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [questionGroups, setQuestionGroups] = useState([]); // new state
  const [newGroupName, setNewGroupName] = useState(""); // new state
  const [isModalVisible, setIsModalVisible] = useState(false); // new state

  const retrieveClassroomList = async () => {
    let classrooms = getLocalStorageData("classrooms");

    if (classrooms) {
      setAllClassrooms(classrooms);
      // fetchAllClassroomsLSIData();
    }

    const reqClassrooms = await firebase.classrooms().once("value");

    if (
      reqClassrooms.val() &&
      JSON.stringify(reqClassrooms.val()) !== JSON.stringify(classrooms)
    ) {
      setLocalStorageData("classrooms", reqClassrooms.val());
      setAllClassrooms(reqClassrooms.val());
    }
  };

  const fetchAllClassroomsLSIData = async () => {
    try {
      console.log("Starting fetchAllClassroomsLSIData with:", {
        selectedClassrooms,
        allUsers: allUsers
      });

      const lsiDataArrays = await Promise.all(
        selectedClassrooms.map(async (className) => {
          return await fetchSingleClassLSIData(className);
        })
      );

      console.log("Fetched LSI data arrays:", lsiDataArrays);

      const mergedLsiData = { preLsi: [], midLsi: [], postLsi: [] };
      lsiDataArrays.forEach((lsiDataArray) => {
        mergedLsiData.preLsi.push(...lsiDataArray.preLsi);
        mergedLsiData.midLsi.push(...lsiDataArray.midLsi);
        mergedLsiData.postLsi.push(...lsiDataArray.postLsi);
      });

      console.log("Merged LSI data:", mergedLsiData);
      setLsiData(mergedLsiData);

      console.log("About to call addLSIDataToUsers with:", {
        users: allUsers,
        lsiData: mergedLsiData
      });

      addLSIDataToUsers(allUsers, mergedLsiData);
    } catch (error) {
      console.error("Error fetching classroom LSI data:", error);
      toast.error("Error loading classroom data");
    }
  };

  const fetchSingleClassLSIData = async (className) => {
    console.log("fetching pre, mid, and post LSI data for class Id", className);

    // Fetch pre, mid, and post LSI data
    const [preReq, midReq, postReq] = await Promise.all([
      firebase.preLSIAssessmentResultsByClassId(className).get(),
      firebase.midLSIAssessmentResultsByClassId(className).get(),
      firebase.postLSIAssessmentResultsByClassId(className).get()
    ]);

    // Convert pre LSI data
    const preLsiData = preReq.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
      assessmentType: 'pre'
    }));

    // Convert mid LSI data
    const midLsiData = midReq.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
      assessmentType: 'mid'
    }));

    // Convert post LSI data
    const postLsiData = postReq.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
      assessmentType: 'post'
    }));

    // Merge pre, mid and post data
    const mergedLsiData = { preLsi: preLsiData, midLsi: midLsiData, postLsi: postLsiData };

    setLocalStorageData(`multiClassroomLsiData_${className}`, mergedLsiData);
    return mergedLsiData;
  };


  const fetchUsersByClassId = async (selectedClassroomIds) => {
    // queryData = (reference, field, query) => {
    //   return this.db.ref(reference).orderByChild(field).equalTo(query);
    // };

    console.log("fetching users by class id", selectedClassroomIds);

    // create promises for each selectedClassroomId in the array
    const promises = selectedClassroomIds.map((classId) =>
      firebase.queryData("users", "classId", classId).once("value")
    );

    // wait for all promises to resolve
    const snapshots = await Promise.all(promises);

    let allUsers = [];

    // map over the snapshots and return the values
    snapshots.map((snapshot) => {
      const users = snapshot.val();
      if (users) {
        allUsers = [...allUsers, ...Object.entries(users)];
      }
    });

    allUsers = allUsers
      .map((user) => {
        return { ...user[1], uid: user[0] };
      })
      .filter((user) => !user.isAdmin && !user.isMentor);

    return allUsers || [];
  };

  const handleSubmit = async (selectedClassrooms) => {
    const filteredUsers = await fetchUsersByClassId(selectedClassrooms);
    setSelectedClassrooms(selectedClassrooms);
    setAllUsers(filteredUsers);
  };

  const addLSIDataToUsers = (users, lsiData) => {
    if (!users || !lsiData) {
      console.error("Missing data in addLSIDataToUsers:", {
        hasUsers: !!users,
        hasLsiData: !!lsiData,
        users,
        lsiData
      });
      return;
    }
    const usersWithLSIData = users.map((user) => {
      const preLsiData = lsiData.preLsi.find((data) => data.uid === user.uid);
      const midLsiData = lsiData.midLsi.find((data) => data.uid === user.uid);
      const postLsiData = lsiData.postLsi.find((data) => data.uid === user.uid);
      return {
        ...user,
        preLsi: preLsiData || null,
        midLsi: midLsiData || null,
        postLsi: postLsiData || null
      };

    });
    console.log("addLSIDataToUsers userWithLSIData", usersWithLSIData);


    setAllUsers(usersWithLSIData);
  };

  const fetchLSIAssessmentData = async () => {
    try {
      const req = await firebase.assessmentQuestions().get();
      const assessmentQuestions = req.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocalStorageData("assessmentQuestions", assessmentQuestions);
      setAssessmentQuestions(assessmentQuestions);
    } catch (error) {
      console.error("Error fetching LSI assessment data:", error);
      throw error;
    }
  };

  const refreshLSIAssessmentData = async () => {
    console.log("refreshing assessment questions from database");
    const req = await firebase.assessmentQuestions().get();
    const assessmentQuestions = req.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLocalStorageData("assessmentQuestions", assessmentQuestions);
    setAssessmentQuestions(assessmentQuestions);
    console.log("refreshed assessment questions", assessmentQuestions);
  };

  const handleQuestionsSubmit = (selectedQuestions) => {
    console.log("Selected Questions:", selectedQuestions);
    setSelectedQuestions(selectedQuestions);
    setLocalStorageData("selectedQuestions", selectedQuestions);
  };

  const saveQuestionGroup = async () => {
    if (!newGroupName) {
      toast.error("Group name cannot be empty");
      return;
    }

    try {
      await firebase.questionGroups().add({
        name: newGroupName,
        questions: selectedQuestions,
      });
      toast.success("Question group saved successfully");
      setIsModalVisible(false);
      setNewGroupName("");
      fetchQuestionGroups();
    } catch (error) {
      console.error("Error saving question group:", error);
      toast.error("Error saving question group");
    }
  };

  const fetchQuestionGroups = async () => {
    try {
      const req = await firebase.questionGroups().get();
      const groups = req.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuestionGroups(groups);
    } catch (error) {
      console.error("Error fetching question groups:", error);
      toast.error("Error fetching question groups");
    }
  };

  const loadQuestionGroup = (group) => {
    setSelectedQuestions(group.questions);
    setLocalStorageData("selectedQuestions", group.questions);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        await Promise.all([
          retrieveClassroomList(),
          fetchLSIAssessmentData(),
          fetchQuestionGroups(),
        ]);
        const savedSelectedQuestions = getLocalStorageData("selectedQuestions");
        if (savedSelectedQuestions) {
          setSelectedQuestions(savedSelectedQuestions);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
        toast.error("Error loading data");
      }
    };

    loadData();
  }, []);

  // fetch all classrooms lsi data after all the data is loaded
  useEffect(() => {
    if (allClassrooms && selectedClassrooms.length > 0) {
      fetchAllClassroomsLSIData();
    }
  }, [allClassrooms, selectedClassrooms]);

  const sortedUsers = allUsers
    ? [...allUsers].sort((a, b) => {
      const aRiskLevel = selectedQuestions.reduce((acc, selectedQuestion) => {
        // Get pre, mid, and post LSI answers
        const preAnswers = a.preLsi?.lsiAnswers
          ? Array.isArray(a.preLsi.lsiAnswers)
            ? a.preLsi.lsiAnswers
            : Object.entries(a.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = a.midLsi?.lsiAnswers
          ? Array.isArray(a.midLsi.lsiAnswers)
            ? a.midLsi.lsiAnswers
            : Object.entries(a.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = a.postLsi?.lsiAnswers
          ? Array.isArray(a.postLsi.lsiAnswers)
            ? a.postLsi.lsiAnswers
            : Object.entries(a.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        // Use post answers if available, then mid answers, then fall back to pre answers
        const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : selectedQuestion.midLSI ? midAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : preAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions?.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              acc += 1;
            }
          });
        }
        return acc;
      }, 0);

      const bRiskLevel = selectedQuestions.reduce((acc, selectedQuestion) => {
        const preAnswers = b.preLsi?.lsiAnswers
          ? Array.isArray(b.preLsi.lsiAnswers)
            ? b.preLsi.lsiAnswers
            : Object.entries(b.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = b.midLsi?.lsiAnswers
          ? Array.isArray(b.midLsi.lsiAnswers)
            ? b.midLsi.lsiAnswers
            : Object.entries(b.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = b.postLsi?.lsiAnswers
          ? Array.isArray(b.postLsi.lsiAnswers)
            ? b.postLsi.lsiAnswers
            : Object.entries(b.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : selectedQuestion.midLSI ? midAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : preAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions?.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              acc += 1;
            }
          });
        }
        return acc;
      }, 0);

      return bRiskLevel - aRiskLevel;
    })
    : [];

  const dropoutUsers = sortedUsers.filter((user) => user.inactive);
  const nonDropoutUsers = sortedUsers.filter((user) => !user.inactive);

  const averageRiskLevel = (users) => {
    if (users.length === 0) return 0;
    const totalRiskLevel = users.reduce((acc, user) => {
      let userRiskLevel = 0;
      selectedQuestions.forEach((selectedQuestion) => {
        const preAnswers = user.preLsi?.lsiAnswers
          ? Array.isArray(user.preLsi.lsiAnswers)
            ? user.preLsi.lsiAnswers
            : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = user.midLsi?.lsiAnswers
          ? Array.isArray(user.midLsi.lsiAnswers)
            ? user.midLsi.lsiAnswers
            : Object.entries(user.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = user.postLsi?.lsiAnswers
          ? Array.isArray(user.postLsi.lsiAnswers)
            ? user.postLsi.lsiAnswers
            : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : selectedQuestion.midLSI ? midAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : preAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions?.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              userRiskLevel += 1;
            }
          });
        }
      });
      return acc + userRiskLevel;
    }, 0);
    return (totalRiskLevel / users.length).toFixed(2);
  };

  const getMostCommonDropoutQuestions = () => {
    const questionCount = {};

    dropoutUsers.forEach((user) => {
      selectedQuestions.forEach((selectedQuestion) => {
        const preAnswers = user.preLsi?.lsiAnswers
          ? Array.isArray(user.preLsi.lsiAnswers)
            ? user.preLsi.lsiAnswers
            : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = user.midLsi?.lsiAnswers
          ? Array.isArray(user.midLsi.lsiAnswers)
            ? user.midLsi.lsiAnswers
            : Object.entries(user.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = user.postLsi?.lsiAnswers
          ? Array.isArray(user.postLsi.lsiAnswers)
            ? user.postLsi.lsiAnswers
            : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : selectedQuestion.midLSI ? midAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : preAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions?.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              const questionKey = `${selectedQuestion.name} ${option.value}`;
              if (questionCount[questionKey]) {
                questionCount[questionKey]++;
              } else {
                questionCount[questionKey] = 1;
              }
            }
          });
        }
      });
    });

    const sortedQuestions = Object.entries(questionCount).sort(
      (a, b) => b[1] - a[1]
    );

    return sortedQuestions.map(([question, count]) => ({
      question,
      count,
    }));
  };

  const mostCommonDropoutQuestions = getMostCommonDropoutQuestions();

  const getMostCommonNonDropoutQuestions = () => {
    const questionCount = {};

    nonDropoutUsers.forEach((user) => {
      selectedQuestions.forEach((selectedQuestion) => {
        const preAnswers = user.preLsi?.lsiAnswers
          ? Array.isArray(user.preLsi.lsiAnswers)
            ? user.preLsi.lsiAnswers
            : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = user.midLsi?.lsiAnswers
          ? Array.isArray(user.midLsi.lsiAnswers)
            ? user.midLsi.lsiAnswers
            : Object.entries(user.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = user.postLsi?.lsiAnswers
          ? Array.isArray(user.postLsi.lsiAnswers)
            ? user.postLsi.lsiAnswers
            : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : selectedQuestion.midLSI ? midAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        ) : preAnswers.find(
          (answer) => answer.id === selectedQuestion.id
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions?.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              const questionKey = `${selectedQuestion.name}-${option.value}`;
              if (questionCount[questionKey]) {
                questionCount[questionKey]++;
              } else {
                questionCount[questionKey] = 1;
              }
            }
          });
        }
      });
    });

    const sortedQuestions = Object.entries(questionCount).sort(
      (a, b) => b[1] - a[1]
    );

    return sortedQuestions.map(([question, count]) => ({
      question,
      count,
    }));
  };

  const mostCommonNonDropoutQuestions = getMostCommonNonDropoutQuestions();

  if (!authUser.isAdmin) return null;
  if (loading) {
    return (
      <div className="multiclassroom__loading">
        <Spin />
      </div>
    );
  }

  const exportToCSV = () => {
    console.log("exporting to csv...");
    const data = sortedUsers.map((user) => {
      const userData = {
        Name: user.displayName,
        Classroom: user.classId,
        "Pre LSI": user.preLsi ? "✅" : "N/A",
        "Mid LSI": user.midLsi ? "✅" : "N/A",
        "Post LSI": user.postLsi ? "✅" : "N/A",
        "Program Dropout": user.inactive ? "Yes" : "No",
        "LSI Risk Level": selectedQuestions.reduce((acc, selectedQuestion) => {
          const preAnswers = user.preLsi?.lsiAnswers
            ? Array.isArray(user.preLsi.lsiAnswers)
              ? user.preLsi.lsiAnswers
              : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
                ...answer,
                id,
              }))
            : [];
          const midAnswers = user.midLsi?.lsiAnswers
            ? Array.isArray(user.midLsi.lsiAnswers)
              ? user.midLsi.lsiAnswers
              : Object.entries(user.midLsi.lsiAnswers).map(([id, answer]) => ({
                ...answer,
                id,
              }))
            : [];
          const postAnswers = user.postLsi?.lsiAnswers
            ? Array.isArray(user.postLsi.lsiAnswers)
              ? user.postLsi.lsiAnswers
              : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
                ...answer,
                id,
              }))
            : [];

          const userQuestion = selectedQuestion.postLSI ? postAnswers.find(
            (answer) => answer.id === selectedQuestion.id
          ) : selectedQuestion.midLSI ? midAnswers.find(
            (answer) => answer.id === selectedQuestion.id
          ) : preAnswers.find(
            (answer) => answer.id === selectedQuestion.id
          );
          if (userQuestion) {
            const questionOptions = selectedQuestion.options;
            questionOptions?.forEach((option) => {
              if (option.isRisky && option.value === userQuestion.value) {
                acc += 1;
              }
            });
          }
          return acc;
        }, 0),
      };

      // Add both pre and post LSI answers to the CSV
      selectedQuestions.forEach((question) => {
        const preAnswers = user.preLsi?.lsiAnswers
          ? Array.isArray(user.preLsi.lsiAnswers)
            ? user.preLsi.lsiAnswers
            : Object.entries(user.preLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const midAnswers = user.midLsi?.lsiAnswers
          ? Array.isArray(user.midLsi.lsiAnswers)
            ? user.midLsi.lsiAnswers
            : Object.entries(user.midLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];
        const postAnswers = user.postLsi?.lsiAnswers
          ? Array.isArray(user.postLsi.lsiAnswers)
            ? user.postLsi.lsiAnswers
            : Object.entries(user.postLsi.lsiAnswers).map(([id, answer]) => ({
              ...answer,
              id,
            }))
          : [];

        const preAnswer = preAnswers.find((ans) => ans.id === question.id);
        const postAnswer = postAnswers.find((ans) => ans.id === question.id);
        const midAnswer = midAnswers.find((ans) => ans.id === question.id);

        userData[`Pre ${question.name}`] = formatAnswer(preAnswer, question);
        userData[`Post ${question.name}`] = formatAnswer(postAnswer, question);
        userData[`Mid ${question.name}`] = formatAnswer(midAnswer, question);
      });

      return userData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "multi_classroom_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Helper function to format answers consistently
  const formatAnswer = (answer, question) => {
    if (!answer) return "N/A";

    if (question.type === "checkbox" && answer.checked) {
      return Object.values(answer.checked)
        .filter((item) => item.checked)
        .map((item) => item.name)
        .join(", ") || "N/A";
    }

    return answer.value || "N/A";
  };

  return (
    <div className="multiclassroom__container">
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          // position: "absolute",
          // top: 70,
          gap: 8,
          right: 20,
        }}
      >
        <Button
          onClick={() => {
            removeLocalStorageData("classrooms");
            removeLocalStorageData("users");
            removeLocalStorageData("assessmentQuestions");
            removeLocalStorageData("selectedQuestions");
            removeLocalStorageData("multiClassroomLsiData");
            removeLocalStorageData("multiClassroomLsiData");
            removeLocalStorageData("questionGroups");
            window.location.reload();
          }}
        >
          Clear Local Storage
        </Button>
        <Button>Download CSV</Button>
      </div>
      <MultiClassroomSelect
        classrooms={
          allClassrooms
            ? Object.values(allClassrooms).map(
              (classroom) => classroom.className
            )
            : []
        }
        handleSubmit={handleSubmit}
      />
      <LSIAssessmentQuestionsMultiSelect
        questions={assessmentQuestions}
        handleSubmit={handleQuestionsSubmit}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
      />

      {selectedQuestions.length !== 0 && (
        <div className="multiclassroom__categoryoptions">
          <div
            className="multiclassroom__button"
            onClick={() => setIsModalVisible(true)}
          >
            Save New Question Group
          </div>
          <div
            className="multiclassroom__button"
            onClick={() => {
              setSelectedQuestions([]);
              removeLocalStorageData("selectedQuestions", selectedQuestions);
            }}
          >
            Clear Selected Questions
          </div>
        </div>
      )}

      <div className="classroom__datapoints">
        <div>
          <div className="categories__title">Categories</div>

          <div className="categories__container">
            {questionGroups.map((group) => (
              <div
                className="category__category"
                key={group.id}
                onClick={() => loadQuestionGroup(group)}
              >
                {group.name}
              </div>
            ))}
          </div>
        </div>

        <Button
          onClick={() => {
            exportToCSV();
          }}
        >
          Export to CSV
        </Button>
        <div>
          <div className="data__content">
            {allUsers && <div>Total Users {allUsers.length}</div>}
            <div>
              Program Dropout Rate:{" "}
              {Number(
                (allUsers?.filter((user) => user.inactive).length /
                  allUsers?.length) *
                100
              ).toFixed(2)}
              %
            </div>
            <div>Number of Dropouts {dropoutUsers.length}</div>
          </div>
          <div>Avg. Risk Level</div>
          <div className="data__content">
            <div>Dropouts: {averageRiskLevel(dropoutUsers)}</div>
            <div>Non-Dropouts: {averageRiskLevel(nonDropoutUsers)}</div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          title="Save Question Group"
          visible={isModalVisible}
          onOk={saveQuestionGroup}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input
            placeholder="Enter group name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </Modal>
      </div>

      <MultiClassroomsTable
        selectedQuestions={selectedQuestions}
        sortedUsers={sortedUsers}
        setToastContent={setToastContent}
      />

      <Insights
        mostCommonDropoutQuestions={mostCommonDropoutQuestions}
        mostCommonNonDropoutQuestions={mostCommonNonDropoutQuestions}
        dropoutUsers={dropoutUsers}
        nonDropoutUsers={nonDropoutUsers}
      />
      {/* Clear localstorage buttons */}
    </div>
  );
};

export default compose(withFirebase)(MultiClassroomView);
