import React from "react";
import "./index.css";

const ToggleSwitch = ({ userType }) => {
  return (
    <div className={`loginModal-toggle-component ${userType === 'mentor' ? ' active' : ''}`}>
      <div className="loginModal-toggle-button">
        <div className='loginModal-toggle-text-button'
          style={{ color: userType === 'mentor' ? '#FB7C23' : '#D94645' }}>
          {userType === 'mentor' ? 'Mentor' : 'Student'}
        </div>
      </div>
    </div>
  )
}


export default ToggleSwitch;