import React, { Component, PureComponent } from "react";

import "./index.css";
import { Rater } from "./Rater";

export class Likert extends Component {
  state = { updating: false };

  componentDidUpdate(prevProps, prevState) {
    // console.log("Likert 6 | parent updated");
  }

  componentDidMount() {
    // console.log("Likert 16 | component did mount!");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.updating) return true;
    return false;
  }

  handleUpdate = () => {
    this.setState({ updating: true }, () => this.setState({ updating: false }));
  };

  render() {
    const { index, item, onLikertChange } = this.props;
    return (
      <div className={`${this.props.fromEmergencyModal ? "likert-emergency" : "likert"}`}>
        <p>
          {index}. {item.question}
        </p>
        {!this.props.lessonComplete && (
          <Rater
            index={index}
            onLikertChange={onLikertChange}
            question={item.question}
            scaleValue={item.value}
            handleUpdate={this.handleUpdate}
            category={item.category}
          />
        )}
      </div>
    );
  }
}

export default Likert;
