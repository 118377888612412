import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "antd/es/modal";
import CustomButton from "src/components/shared/Button";
import Likert from "src/components/ViewSection/sectionComponents/Likert";
import { toast } from "react-toastify";


const EmergencyModal = ({ closeModal, authUser, firebase }) => {
  const dispatch = useDispatch();

  const [responses, setResponses] = React.useState({});
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  const onLikertChange = (value, index, question, category) => {
    setResponses(prev => ({
      ...prev,
      [index]: {
        category,
        question,
        value
      }
    }));
  }

  // Check if all likert questions have been answered
  React.useEffect(() => {
    // Count how many likert questions we have
    const likertQuestionCount = data.filter(item => item.type === "likert").length;

    // Check if we have the same number of responses
    const isComplete = Object.keys(responses).length === likertQuestionCount;
    setIsFormComplete(isComplete);
  }, [responses]);

  const saveData = async () => {
    // Check if all likert questions have been answered
    const likertIndices = data
      .map((item, index) => item.type === "likert" ? index : null)
      .filter(index => index !== null);

    // Check if we have responses for all likert indices
    const isComplete = likertIndices.every(index => responses[index]);

    if (!isComplete) {
      // Show error message
      toast.error("Please answer all questions before continuing.");
      return;
    }

    const uid = authUser.uid;
    const lessonId = '-M9Ym0Bs7mtcVB70vhOp';
    const LIKERT_DB_DOC_NAME = `${uid}_${lessonId}`;

    try {
      const likertRequest = await firebase
        .getLikertData()
        .child(LIKERT_DB_DOC_NAME)
        .once("value");
      if (likertRequest.val()) {
        console.log("ViewSection 468 | sent data to post test");
        await firebase
          .updateLikertData()
          .child(LIKERT_DB_DOC_NAME)
          .update({
            postTest: responses,
          });
        await firebase.user(uid).update({
          hasEmergencyModal: false,
        });
      } else {
        await firebase
          .updateLikertData()
          .child(LIKERT_DB_DOC_NAME)
          .update({
            preTest: responses,
          });
        await firebase.user(uid).update({
          hasEmergencyModal: false,
        });
      }
    } catch (error) {
      console.log("ViewSection 468 | error", error.message);
    }
  }


  const data = [
    {
      "type": "text",
      "value": "<h3>To reevaluate your present understanding of the topics covered in this module, please respond using the rating scale below each statement.</h3>"
    },
    {
      "category": "",
      "question": "Did you know that being aware of your own emotions can help you in every single situation that face you in life?",
      "type": "likert"
    },
    {
      "category": "",
      "question": "How confident are you that you could use your own emotions in a positive way to change the outcomes of other's emotions?",
      "type": "likert"
    },
    {
      "category": "",
      "question": "If you got really angry or scared today would you know what to do to calm yourself down in a scientific method that actually works?",
      "type": "likert"
    },
    {
      "category": "",
      "question": "If someone else is behaving in a difficult way would you feel comfortable handling it with empathy and figuring out what they really need?",
      "type": "likert"
    },
    {
      "category": "",
      "question": "How confident do you feel about your ability to identify (put into words) any of the emotions you feel at any given time? E.G.  Right now, I feel anxious or content.",
      "type": "likert"
    },
    {
      "category": "",
      "question": "Do you feel that you know how important emotional intelligence is for your life and relationships?",
      "type": "likert"
    }
  ]


  return (
    <Modal
      title="Please complete the following form"
      visible={true}
      footer={null}
      onCancel={closeModal}
      onOk={closeModal}
    >
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 20 }}>There was a problem saving previous data. Please complete the following form to continue.</div>
        {data.map((item, index) => (
          item.type === "likert" && (
            <Likert
              key={index}
              index={index}
              item={item}
              onLikertChange={(value, idx, question, category) => onLikertChange(value, idx, question, category)}
              fromEmergencyModal={true}
            />
          )
        ))}
      </div>

      <div style={{ marginTop: 20 }}>
        <CustomButton
          title="Save"
          onClick={saveData}
        />
      </div>

    </Modal>
  );
};

export default EmergencyModal;
