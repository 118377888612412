import React, { memo, useState, useEffect } from "react";
import "./index.css";

const AdminClassrooms = ({ classrooms, authUser, firebase }) => {
  const [filter, setFilter] = useState(null);
  const [favorites, setFavorites] = useState([]);

  // Load favorites and potentially set a filter based on authUser
  useEffect(() => {
    const loadedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(loadedFavorites);
    if (authUser.isAdmin) {
      setFilter(authUser.classId);
    }
  }, [authUser]);

  const handleClassroomClick = (classId, className) => {
    if (!authUser.isAdmin && authUser.isMentor) {
      onSubmitJoinClassroom(className, classId);
    }
  };

  // Toggle the favorite status of a classroom
  const toggleFavorite = (classId) => {
    const isFavorite = favorites.includes(classId);
    const updatedFavorites = isFavorite
      ? favorites.filter((id) => id !== classId)
      : [...favorites, classId];

    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  const onSubmitJoinClassroom = async (className, classId) => {
    // change this to a firebase database query
    firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(async (classrooms) => {
        if (classrooms.val()) {
          let classroomToJoin = Object.entries(classrooms.val())[0];
          if (classroomToJoin) {
            const { settings } = classroomToJoin[1];

            try {
              await firebase.user(authUser.uid).update({
                classId: className,
                classUID: classroomToJoin[0],
                introVideoDone: settings.introVideoAvailable ? false : true,
              });
              // await insertExistingCalendarIntoUserCalendars(calendarId);

              //   dispatch(getAllModules(authUser, classroomToJoin[1]));
              // get classroom data
            } catch (error) { }
          }
        }
      });
  };

  // Clear the filter to show all classrooms
  const showAllClassrooms = () => setFilter(null);

  // Apply filter to show only user's classroom and favorites
  const hideOtherClassrooms = () => setFilter(authUser.classId);

  // Access control for non-admin or non-mentor users
  if (!authUser.isAdmin && !authUser.isMentor) return null;

  return (
    <div className="adminclassrooms">
      <h5 style={{ fontWeight: "bold", marginBottom: 8 }}>Classrooms</h5>
      <div className="adminclassrooms__container">
        {classrooms &&
          Object.entries(classrooms)
            .filter(([classId, classInfo]) =>
              filter
                ? classInfo.className === filter || favorites.includes(classId)
                : true
            )
            .map(([classId, classInfo], index) => {
              if (
                authUser.isAdmin ||
                (authUser.isMentor &&
                  classInfo.teachers &&
                  Object.values(classInfo.teachers).some(
                    (teacher) => teacher.email === authUser.email
                  ))
              ) {
                return (
                  <div
                    className="adminclassroom__container"
                    style={{
                      border:
                        classInfo.className === authUser.classId &&
                          !authUser.isAdmin &&
                          authUser.isMentor
                          ? "1px solid #d94645"
                          : "none",
                      cursor:
                        classInfo.className === authUser.classId &&
                          !authUser.isAdmin &&
                          authUser.isMentor
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      handleClassroomClick(classId, classInfo.className);
                    }}
                    key={index}
                  >
                    {classInfo.className}
                    <a href={`viewClassroom/${classId}`}>View Class</a>
                    <button
                      onClick={() => toggleFavorite(classId)}
                      style={{ marginLeft: "10px" }}
                    >
                      {favorites.includes(classId) ? "★" : "☆"}
                    </button>
                  </div>
                );
              }
              return null;
            })}
        {authUser.isAdmin && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#1890ff",
              cursor: "pointer",
            }}
            onClick={filter ? showAllClassrooms : hideOtherClassrooms}
          >
            {filter ? "See all classrooms..." : "Just show my classroom..."}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AdminClassrooms);
