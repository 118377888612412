import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "src/components/shared/Button";
import Input from "src/components/shared/Input";
import "./index.css";

const LegalNameAndPhoneModal = ({ firebase, authUser }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [legalName, setLegalName] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue = inputValue.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );
    console.log("PhoneNumberModal 25 | capitalized value", capitalizedValue);
    setLegalName(capitalizedValue);
  };

  console.log("authuser in phonenumbermodal", authUser);

  if (typeof authUser?.phoneNumber === "undefined" && !authUser?.isMentor) {
    return (
      <div className="phonenumbermodal__container">
        <div className="phonenumbermodal__content">
          <div className="phonenumbermodal__title">
            Legal name and phone number
          </div>
          <div className="phonenumbermodal__info">
            <span style={{ color: "red", fontWeight: "bold" }}>
              Avoid Legal Issues:
            </span>
            Input your{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              FULL LEGAL NAME
            </span>{" "}
            as it appears on your{" "}
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              Driver’s License or State-Issued ID
            </span>
          </div>

          <div className="phonenumbermodal__input">
            <Input
              placeholder="John Doe"
              value={legalName}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="phonenumbermodal__info">
            Please add your phone number so we can send important program info
            to you.
          </div>

          <div className="phonenumbermodal__input">
            <Input
              placeholder="(404) 698-8906"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
          <div className="phonenumbermodal__button">
            <Button
              title="Save"
              type={`${
                phoneNumber === "" || legalName === "" ? "disabled" : "primary"
              }`}
              loading={loading}
              onClick={async () => {
                setLoading(true);

                await firebase
                  .user(authUser.uid)
                  .update({ phoneNumber: phoneNumber, displayName: legalName })
                  .then(() => {
                    setLoading(false);
                    toast.success("Legal Name and Phone Number updated!");
                  });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default LegalNameAndPhoneModal;
