import React, { memo, useState, useEffect } from "react";

import { AuthUserContext } from "./index";
import { withFirebase } from "../Firebase";
import { openNotification } from "../../utils/notifications";
import { compose } from "recompose";
import { withRouter } from "react-router";

const withAuthentication = (Component) => {
  // const WithAuthentication = (props) => {
  //   const [authUser, setAuthUser] = useState(
  //     JSON.parse(localStorage.getItem("authUser"))
  //   );

  //   useEffect(() => {
  //     const listener = props.firebase.onAuthUserListener(
  //       async (authUser) => {
  //         localStorage.setItem("authUser", JSON.stringify(authUser));
  //         setAuthUser(authUser);
  //       },
  //       () => {
  //         console.log("withAuthentication.js 21 | no user");
  //         localStorage.removeItem("authUser");
  //         setAuthUser(null);
  //       }
  //     );
  //     return () => {
  //       listener();
  //     };
  //   }, []);

  //   return (
  //     <AuthUserContext.Provider value={authUser}>
  //       <Component {...props} />
  //     </AuthUserContext.Provider>
  //   );
  // };

  class WithAuthentication extends React.Component {
    state = {
      authUser: JSON.parse(localStorage.getItem("authUser")),
      providerData: null,
    };

    listener = () => {
      // return this.props.firebase.onAuthUserListener();
    };

    async componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        async (authUser) => {
          // console.log(
          //   "withAuthentication.js 48 | listened to firebase user update",
          //   authUser
          // );
          localStorage.setItem("authUser", JSON.stringify(authUser));
          // localStorage.setItem("refreshToken", authUser.refresh_token);
          this.setState({
            authUser,
          });
        },
        () => {
          localStorage.removeItem("authUser");

          this.setState({ authUser: null });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return compose(withFirebase)(WithAuthentication);
};

export default withAuthentication;
