import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

export const JoinClassInstructionsDoc = ({
  classUrl,
  loginType,
  qrCodeDataUrl,
  isDiversion,
}) => {
  // const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");

  console.log("generatign qr code for class url:", classUrl);

  useEffect(() => {}, []);

  return (
    <Document title={"Join Instructions"}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Join Class Instructions</Text>
          <Text style={styles.instructions}>
            To join the class, please scan the QR code below or visit the
            following link:
          </Text>
          <Text style={styles.link}>{`myreded.com/${
            isDiversion ? "diversion" : ""
          }?classid=${classUrl
            .split(" ")
            .join("%20")}&loginType=${loginType}`}</Text>
          {qrCodeDataUrl && <Image style={styles.qrCode} src={qrCodeDataUrl} />}
          <Text style={styles.footer}>
            If you have any issues, please contact your instructor or support.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  qrCode: {
    width: 200,
    height: "auto",
    marginVertical: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  instructions: {
    fontSize: 16,
    marginBottom: 10,
  },
  link: {
    fontSize: 14,
    color: "blue",
    textDecoration: "underline",
    marginBottom: 20,
  },
  footer: {
    fontSize: 12,
    marginTop: 20,
    color: "gray",
  },
});
